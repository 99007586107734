<template>
  <div class="sidemenu-wrapper" ss-container>
    <div class="logo-area">
      <h2>Customer System</h2>
    </div>
    <ul class="sidemenu">
      <li class="menu-item">
        <router-link to="/dashboard">
          <i class="fas fa-home"></i>
          Home
        </router-link>
      </li>
      <li class="menu-item item-parent">
        <a href="#" v-on:click="openSubmenu">
          <i class="fas fa-male"></i>
          Human Resource
        </a>
        <ul class="item-submenu">
          <li class="menu-item">
            <router-link to="/hr/employees">Employees</router-link>
          </li>
          <li class="menu-item" v-if="checkIsAdmin()">
            <router-link to="/hr/groups">Employee Groups</router-link>
          </li>
          <li class="menu-item item-parent">
            <a href="#" v-on:click="openSubmenu">Attendance <i class="el-icon-arrow-right"></i></a>
            <ul class="item-submenu">
              <li class="menu-item" v-if="checkIsAdmin()">
                <router-link to="/hr/attendance">Attendance Overview</router-link>
              </li>
              <li class="menu-item">
                <router-link to="/hr/attendance/my">My Attendance Record</router-link>
              </li>
              <li class="menu-item" v-if="checkIsAdmin()">
                <router-link to="/hr/attendance/applications">Applications Overview</router-link>
              </li>
              <li class="menu-item">
                <router-link to="/hr/attendance/applications/my">My Applications</router-link>
              </li>
            </ul>
          </li>
          <li class="menu-item item-parent">
            <a href="#" v-on:click="openSubmenu">Leave <i class="el-icon-arrow-right"></i></a>
            <ul class="item-submenu">
              <li class="menu-item" v-if="checkIsAdmin()">
                <router-link to="/hr/leave/applications">Application Overview</router-link>
              </li>
              <li class="menu-item">
                <router-link to="/hr/leave/applications/my">My Application</router-link>
              </li>
            </ul>
          </li>
          <li class="menu-item">
            <router-link to="/hr/schedule">Workshift</router-link>
          </li>
          <li class="menu-item" v-if="checkIsAdmin()">
            <router-link to="/hr/attendance/worktime">Worktime Setting</router-link>
          </li>
        </ul>
      </li>
      <li class="menu-item item-parent">
        <a href="#" v-on:click="openSubmenu">
          <i class="el-icon-s-cooperation"></i>
          Work Managment
        </a>
        <ul class="item-submenu">
          <li class="menu-item" v-if="checkIsAdmin()">
            <router-link to="/work/overview">Overview</router-link>
          </li>
          <li class="menu-item">
            <router-link to="/work/my">My Related Work</router-link>
          </li>
          <li class="menu-item" v-if="checkIsAdmin()">
            <router-link to="/work/allocation">HR Allocation Overview</router-link>
          </li>
          <li class="menu-item" v-if="checkIsAdmin()">
            <router-link to="/work/timetable/overview">Projects Timetable</router-link>
          </li>
          <li class="menu-item">
            <router-link to="/work/timetable/my">My Work Timetable</router-link>
          </li>
        </ul>
      </li>
      <li class="menu-item item-parent">
        <a href="#" v-on:click="openSubmenu">
          <i class="fas fa-money-bill"></i>
          Sales Managment
        </a>
        <ul class="item-submenu">
          <li class="menu-item">
            <router-link to="/sales/customers">Customers List</router-link>
          </li>
          <li class="menu-item">
            <router-link to="/sales/orders">Orders List</router-link>
          </li>
          <li class="menu-item">
            <router-link to="/sales/sns">SNS Managment</router-link>
          </li>
          <li class="menu-item" v-if="checkIsAdmin()">
            <router-link to="/sales/sns/setting">SNS Setting</router-link>
          </li>
        </ul>
      </li>
      <li class="menu-item item-parent">
        <a href="#" v-on:click="openSubmenu">
          <i class="fas fa-comment-dollar"></i>
          Payroll / Financial
        </a>
        <ul class="item-submenu">
          <li class="menu-item item-parent">
            <a href="#" v-on:click="openSubmenu">Payroll<i class="el-icon-arrow-right"></i></a>
            <ul class="item-submenu">
              <li class="menu-item" v-if="checkIsAdmin()">
                <router-link to="/financial/payroll/overview">Payroll Overview</router-link>
              </li>
              <li class="menu-item" v-if="checkIsAdmin()">
                <router-link to="/financial/payroll/addition">Addition Payrolls</router-link>
              </li>
              <li class="menu-item">
                <router-link :to="`/financial/payroll/${userId}`">My Payroll</router-link>
              </li>
            </ul>
          </li>
          <li class="menu-item item-parent">
            <a href="#" v-on:click="openSubmenu">Expense Claim<i class="el-icon-arrow-right"></i></a>
            <ul class="item-submenu">
              <li class="menu-item" v-if="checkIsAdmin()">
                <router-link to="/financial/expense/overview">Expense Overview</router-link>
              </li>
              <li class="menu-item">
                <router-link to="/financial/expense/my">My Expense Application</router-link>
              </li>
              <li class="menu-item" v-if="checkIsAdmin()">
                <router-link to="/financial/expense/statistic/staff">Staff Statistics</router-link>
              </li>
              <li class="menu-item" v-if="checkIsAdmin()">
                <router-link to="/financial/expense/statistic/type">Expense Type Statistics</router-link>
              </li>
            </ul>
          </li>
          <li class="menu-item item-parent">
            <a href="#" v-on:click="openSubmenu">Payment<i class="el-icon-arrow-right"></i></a>
            <ul class="item-submenu">
              <li class="menu-item">
                <router-link to="/financial/payment/overview">Payment Overview</router-link>
              </li>
              <li class="menu-item">
                <router-link to="/financial/payment/completed">Completed Payment</router-link>
              </li>
              <li class="menu-item" v-if="checkIsAdmin()">
                <router-link to="/financial/payment/statistics">Payment Statistics</router-link>
              </li>
            </ul>
          </li>
          <li class="menu-item item-parent">
            <a href="#" v-on:click="openSubmenu">Trade<i class="el-icon-arrow-right"></i></a>
            <ul class="item-submenu">
              <li class="menu-item">
                <router-link to="/financial/trade/normal">Normal Trades</router-link>
              </li>
              <li class="menu-item">
                <router-link to="/financial/trade/transaction">Transaction Trades</router-link>
              </li>
            </ul>
          </li>
          <li class="menu-item">
            <router-link to="/financial/income-outcome">Other Income / Outcome</router-link>
          </li>
          <li class="menu-item">
            <router-link to="/financial/statement">Bank Statement</router-link>
          </li>
          <li class="menu-item" v-if="checkIsAdmin()">
            <router-link to="/financial/setting">Financial Year Setting</router-link>
          </li>
          <li class="menu-item" v-if="checkIsAdmin()">
            <router-link to="/financial/report">Profit and Loss Report</router-link>
          </li>
        </ul>
      </li>
      <li class="menu-item item-parent">
        <a href="#" v-on:click="openSubmenu">
          <i class="fas fa-search"></i>
          Report
        </a>
        <ul class="item-submenu">
          <li class="menu-item" v-if="checkIsAdmin()">
            <router-link to="/report/admin">Admin Report</router-link>
          </li>
          <li class="menu-item">
            <router-link to="/report/my">My Report</router-link>
          </li>
          <li class="menu-item" v-if="checkIsAdmin()">
            <router-link to="/backup">Data Backup</router-link>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Common from '@/lib/common';

export default {
  name: 'Sidemenu',
  data(){
    const loginInfo = Common.getLoginInfo();
    return{
      userId: loginInfo['userId'],
    };
  },
  methods:{
    checkIsAdmin(){
      const currentUser = this.currentUser;
      if(currentUser){
        const isAdmin = Common.checkIsAdmin(currentUser);
        return isAdmin;
      }
      return false;
    },
    openSubmenu(e){
      const submenuLink = e.target;
      let submenu = $(submenuLink).parent().find('> .item-submenu');
      //If the target is icon
      if($(submenuLink).hasClass('el-icon-arrow-right')){
        submenu = $(submenuLink).parent().parent().find('> .item-submenu');
      }

      if(!submenu.hasClass('active')){
        $(submenu).css('display', 'block');
        $(submenu).addClass('active');
      }else{
        $(submenu).css('display', 'none');
        $(submenu).removeClass('active');
      }
    },
  },
  computed: mapState({
    currentUser: state => state.currentUser,
  }),
}
</script>

<style lang="scss">
.sidemenu-wrapper{
  background-color: white;
  height: 100%;
  bottom: 0;
  float: none;
  left: 0;
  position: fixed;
  top: 0;
  padding-top: 1.3em;
  padding-bottom: 1.3em;
  box-shadow: 0 14px 28px rgba(0,0,0,.25),0 10px 10px rgba(0,0,0,.22);

  .logo-area{
    color: black;
    margin-bottom: 1em;

    h2{
      font-size: 18px;
    }
  }

  .menu-item{
    font-size: 17px;
  }

  .sidemenu{
    padding-left: 1.3em;
    padding-right: 1.3em;
    margin-top: 30px;
    list-style: none;

    .menu-item{
      margin-top: 0.5em;
      padding-bottom: 5px;
      margin-bottom: 10px;

      &:last-child{
        border: 0;
      }

      a{
        text-decoration: none;
        font-size: 1.2em;
        color: rgba(0, 0, 0, 0.65);

        &:hover{
          color: rgb(24, 144, 255);
        }
      }

      i{
        margin-right: 0.5em;
      }
      .fa-male{
        font-size: 1.1em;
        margin-right: 15px;
        margin-left: 5px;
      }
      .fa-money-bill{
        font-size: 0.8em;
        margin-right: 10px;
      }
    }

    .item-submenu{
      list-style: none;
      padding-left: 30px;
      padding-top: 10px;
      display: none;
    }
  }
}
</style>
