<template>
  <div class="content-wrapper">
    <div class="home main-content">
      <div class="page-header">
        <h1>Dashboard</h1>
      </div>

      <div class="welcome-message">
        <p>Hello!</p>
        <p>Today is {{currentDate}} !</p>
      </div>

      <div class="dashboard">
        <div class="dashboard-item">
          <h2>Human Resource</h2>
          <div class="subitems">
            <div class="card subitem">
              <router-link to="/hr/employees" class="link">
                <i class="item-icon el-icon-user-solid"></i>
                Employees
              </router-link>
            </div>
            <div class="card subitem">
              <router-link to="/hr/groups" class="link">
                <i class="item-icon fas fa-users"></i>
                Employee Groups
              </router-link>
            </div>
            <div class="card subitem">
              <router-link to="/hr/attendance" class="link">
                <i class="item-icon fas fa-clock"></i>
                Attendance
              </router-link>
            </div>
            <div class="card subitem">
              <router-link to="/hr/leave/applications" class="link">
                <i class="item-icon fas fa-user-clock"></i>
                Leave
              </router-link>
            </div>
            <div class="card subitem">
              <router-link to="/hr/schedule" class="link">
                <i class="item-icon fas fa-calendar-week"></i>
                Workshift
              </router-link>
            </div>
          </div>
        </div>
        <div class="dashboard-item">
          <h2>Work Managment</h2>
          <div class="subitems">
            <div class="card subitem" v-if="checkIsAdmin()">
              <router-link to="/work/overview" class="link">
                <div class="item-icon"><i class="el-icon-s-cooperation"></i></div>
                Work Overview
              </router-link>
            </div>
            <div class="card subitem">
              <router-link to="/work/my" class="link">
                <i class="item-icon fas fa-clipboard-list"></i>
                My Related Work
              </router-link>
            </div>
            <div class="card subitem" v-if="checkIsAdmin()">
              <router-link to="/work/allocation" class="link">
                <i class="item-icon fas fa-chart-bar"></i>
                HR Allocation Overview
              </router-link>
            </div>
            <div class="card subitem" v-if="checkIsAdmin()">
              <router-link to="/work/timetable/overview" class="link">
                <i class="item-icon fas fa-calendar"></i>
                Projects Timetable
              </router-link>
            </div>
            <div class="card subitem">
              <router-link to="/work/timetable/my" class="link">
                <i class="item-icon fas fa-calendar-alt"></i>
                My Work Timetable
              </router-link>
            </div>
          </div>
        </div>
        <div class="dashboard-item">
          <h2>Sales</h2>
          <div class="subitems">
            <div class="card subitem">
              <router-link to="/sales/customers" class="link">
                <div class="item-icon"><i class="fas fa-user-friends"></i></div>
                Customers List
              </router-link>
            </div>
            <div class="card subitem">
              <router-link to="/sales/orders" class="link">
                <i class="item-icon el-icon-s-order"></i>
                Orders List
              </router-link>
            </div>
            <div class="card subitem">
              <router-link to="/sales/sns" class="link">
                <i class="item-icon fab fa-facebook"></i>
                SNS Managment
              </router-link>
            </div>
          </div>
        </div>
        <div class="dashboard-item">
          <h2>Payroll / Financial</h2>
          <div class="subitems">
            <div class="card subitem" v-if="checkIsAdmin()">
              <router-link to="/financial/payroll/overview" class="link">
                <div class="item-icon"><i class="fas fa-money-check-alt"></i></div>
                Payroll
              </router-link>
            </div>
            <div class="card subitem" v-if="!checkIsAdmin()">
              <router-link :to="`/financial/payroll/${userId}`" class="link">
                <div class="item-icon"><i class="fas fa-money-check-alt"></i></div>
                My Payroll
              </router-link>
            </div>
            <div class="card subitem" v-if="checkIsAdmin()">
              <router-link to="/financial/expense/overview" class="link">
                <i class="item-icon fas fa-comment-dollar"></i>
                Expense Claim
              </router-link>
            </div>
            <div class="card subitem" v-if="!checkIsAdmin()">
              <router-link to="/financial/expense/my" class="link">
                <i class="item-icon fas fa-comment-dollar"></i>
                Expense Claim
              </router-link>
            </div>
            <div class="card subitem">
              <router-link to="/financial/payment/overview" class="link">
                <i class="item-icon fas fa-money-bill"></i>
                Payment Overview
              </router-link>
            </div>
            <div class="card subitem">
              <router-link to="/financial/trade/normal" class="link">
                <i class="item-icon fas fa-people-arrows"></i>
                Trade
              </router-link>
            </div>
            <div class="card subitem">
              <router-link to="/financial/income-outcome" class="link">
                <i class="item-icon fas fa-money-bill-wave"></i>
                Other Income / Outcome
              </router-link>
            </div>
            <div class="card subitem" v-if="checkIsAdmin()">
              <router-link to="/financial/statement" class="link">
                <i class="item-icon fas fa-university"></i>
                Bank Statement
              </router-link>
            </div>
            <div class="card subitem" v-if="checkIsAdmin()">
              <router-link to="/financial/report" class="link">
                <i class="item-icon fas fa-chart-line"></i>
                Profit and Loss Report
              </router-link>
            </div>
          </div>
        </div>
        <div class="dashboard-item">
          <h2>Report</h2>
          <div class="subitems">
            <div class="card subitem" v-if="checkIsAdmin()">
              <router-link to="/sales/customers" class="link">
                <div class="item-icon"><i class="fas fa-chart-bar"></i></div>
                Admin Report
              </router-link>
            </div>
            <div class="card subitem">
              <router-link to="/sales/orders" class="link">
                <i class="item-icon fas fa-chart-area"></i>
                My Report
              </router-link>
            </div>
            <div class="card subitem" v-if="checkIsAdmin()">
              <router-link to="/sales/sns" class="link">
                <i class="item-icon fab fa-database"></i>
                Data Backup
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState, mapActions } from 'vuex';
import Sidemenu from '@/components/Sidemenu.vue';
import Common from '@/lib/common';

export default {
  name: 'Home',
  components: {
    Sidemenu
  },
  data(){
    const loginInfo = Common.getLoginInfo();
    const langcode = this.$i18n.locale;

    let dateFormat = 'DD MMMM YYYY, dddd';
    let currentDate = Common.getCurrentDate(dateFormat, 'en');

    if(langcode === 'zh-TW'){
      dateFormat = 'YYYY年M月D日，dddd';
      currentDate = Common.getCurrentDate(dateFormat, 'zh-hk');
    }

    return {
      'currentDate': currentDate,
       userId: loginInfo['userId'],
    }
  },
  methods:{
    checkIsAdmin(){
      const currentUser = this.currentUser;
      if(currentUser){
        const isAdmin = Common.checkIsAdmin(currentUser);
        return isAdmin;
      }
      return false;
    },
  },
  computed: mapState({
   langcode: state => state.langcode,
   currentUser: state => state.currentUser,
 }),
}
</script>

<style scoped lang="scss">
.welcome-message{
  margin-bottom: 50px;
}
.user-status{
  margin-bottom: 50px;
  padding : 1em;
  display: flex;
  justify-content: center;
}
.dashboard{
  .dashboard-item{
    padding-right: 20px;
    margin-bottom: 30px;

    .subitems{
      display: flex;
      flex-direction: row;
      padding-top: 20px;
      flex-wrap: wrap;

      .card{
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 20px;
        padding-bottom: 20px;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        margin-right: 20px;
        margin-bottom: 30px;
        justify-content: center;
      }
    }

    .link{
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .item-icon{
      font-size: 2em;
      margin-right: 25px;
    }
  }
}
</style>
